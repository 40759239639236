import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const isServerSide = typeof window === "undefined";
  return new ApolloClient({
    ssrMode: isServerSide,
    uri:
      (isServerSide ? process.env.API_URL : process.env.NEXT_PUBLIC_API_URL) ??
      "https://api.findahelpline.com",
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
