import { AlertClassKey } from "@mui/material/Alert";
import { RatingClassKey } from "@mui/material/Rating";
import {
  alpha,
  darken,
  createTheme as muiCreateTheme,
} from "@mui/material/styles";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";

import { Content } from "./components/ContentProvider";
import { applyFonts } from "./fonts/localFonts";

declare module "@mui/material/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiRating: RatingClassKey;
    MuiAlert: AlertClassKey;
  }
}

// Augment the palette to our custom banner and button colors
declare module "@mui/material/styles" {
  interface TypeBackground {
    banner: React.CSSProperties["color"];
  }

  interface Palette {
    language: Palette["primary"];
    menu: Palette["primary"];
  }

  interface PaletteOptions {
    language?: PaletteOptions["primary"];
    menu?: PaletteOptions["primary"];
  }
}
// Update the Button's color options to include an ochre option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    language: true;
    menu: true;
  }
}

const createTheme = (
  host?: Content["host"],
): ReturnType<typeof muiCreateTheme> => {
  // Load this font definitions from the host if configured
  const defaultFont =
    host?.defaultFontFamily ?
      applyFonts(host?.defaultFontFamily)
    : ['"Lato"', "sans-serif"].join(",");
  const headingFont =
    host?.headingFontFamily ?
      applyFonts(host?.headingFontFamily)
    : ['"p22-mackinac-pro"', "serif"].join(",");
  const headingTypography: TypographyStyleOptions = {
    fontFamily: headingFont,
    fontWeight: 700,
  };

  return muiCreateTheme({
    typography: {
      fontFamily: defaultFont,
      h1: headingTypography,
      h2: headingTypography,
      h3: headingTypography,
      h4: headingTypography,
      h5: headingTypography,
      h6: headingTypography,
      subtitle1: headingTypography,
      subtitle2: headingTypography,
    },
    palette: {
      primary: {
        main: host?.palettePrimaryMain ?? "#2B8E94",
        contrastText: host?.palettePrimaryContrastText ?? "#FFFFFF",
      },
      secondary: {
        main: host?.paletteSecondaryMain ?? "#94BCD9",
        dark: host ? host.paletteSecondaryDark : "#5393C2", // Keep the MUI default if not overridden by the host
        contrastText: host?.paletteSecondaryContrastText ?? "#FFFFFF",
      },
      text: {
        primary: host?.paletteTextPrimary ?? "#0F2027",
        secondary: host?.paletteTextSecondary ?? "#87909E",
      },
      background: {
        default: host?.paletteBackgroundDefault ?? "#F5F9FC",
        paper: host?.paletteBackgroundPaper ?? "#FFFFFF",
        banner: host?.paletteBackgroundBanner ?? "#FFFFFF",
      },
      language: {
        main: host?.paletteLanguageMain ?? "#F7F7F7",
        contrastText: host?.paletteLanguageContrastText ?? "#87909E",
      },
      menu: {
        main: host?.paletteMenuMain ?? "#FFFFFF",
        contrastText: host?.paletteMenuContrastText ?? "#87909E",
      },
      success: {
        main: host?.paletteSuccessMain ?? "#3FA607",
        contrastText: host?.paletteSuccessContrastText ?? "rgba(0, 0, 0, 0.87)",
      },
      error: {
        main: host?.paletteErrorMain ?? "#E8886C",
        contrastText: host?.paletteErrorContrastText ?? "#FFFFFF",
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: "1rem",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor:
              host?.overridesMuiChipRootBackgroundColor ?? "#F7F7F7",
            color: host?.overridesMuiChipRootColor ?? "#87909E",
          },
          colorSecondary: {
            backgroundColor: host?.paletteSecondaryMain ?? "#94BCD9",
            color: host?.paletteSecondaryContrastText ?? "#FFFFFF",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            fontWeight: 700,
            textTransform: "capitalize",
            boxShadow: "none",
          },
          colorInherit: {
            backgroundColor:
              host?.overridesMuiButtonContainedBackgroundColor ?? "#F7F7F7",
            "&:hover": {
              backgroundColor: darken(
                host?.overridesMuiButtonContainedBackgroundColor ?? "#F7F7F7",
                0.14,
              ),
            },
          },
          containedSecondary: {
            "&:hover": {
              backgroundColor: darken(
                host?.paletteSecondaryMain ?? "#94BCD9",
                0.14,
              ),
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.8rem",
            backgroundColor:
              host?.overridesMuiTooltipTooltipBackgroundColor ??
              "rgba(15,32,39,0.9)",
            borderRadius: 8,
          },
          arrow: {
            color: host?.overridesMuiTooltipArrowColor ?? "rgba(15,32,39,0.9)",
          },
        },
      },
      MuiRating: {
        styleOverrides: {
          iconFilled: {
            color: host?.overridesMuiRatingIconFilledColor ?? "#ECE686",
          },
          iconEmpty: {
            color: host?.overridesMuiRatingIconEmptyColor ?? "#F7F7F7",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor:
              host?.overridesMuiTabsIndicatorBackgroundColor ??
              "rgba(15,35,45,0.5)",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderBottom: "0.603865px solid",
            borderBottomColor:
              host?.overridesMuiTabRootBorderBottomColor ?? "#ABB2B5",
            textTransform: "capitalize",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            border: host?.overridesMuiOutlinedInputBorder,
            backgroundColor:
              host?.overridesMuiOutlinedBackgroundColor ?? "#FFFFFF",
            "& input::placeholder": {
              color: "#87909E",
              opacity: 1,
            },
            "&.Mui-focused": {
              borderColor:
                host?.overridesMuiOutlinedInputFocusedBorderColor ??
                host?.palettePrimaryMain ??
                "#2B8E94",
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
          standardInfo: {
            borderRadius: 0,
            padding: "8px 24px",
            color: host?.overridesMuiAlertStandardInfoColor ?? "#0F2027",
            backgroundColor:
              host?.overridesMuiAlertStandardInfoBackgroundColor ?? "#ECE686",
            "& .MuiAlert-icon": {
              color: host?.overridesMuiAlertStandardInfoIconColor ?? "#0F2027",
            },
          },
          icon: {
            alignItems: "center",
          },
          action: {
            alignItems: "center",
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          // unchecked toggle
          switchBase: {
            color: host?.overridesMuiSwitchUncheckedColor ?? "#808080",
            "&:hover": {
              backgroundColor: alpha(
                host?.overridesMuiSwitchUncheckedColor ?? "#808080",
                0.1,
              ),
            },
          },
          track: {
            backgroundColor:
              host?.overridesMuiSwitchUncheckedColor ?? "#808080",
          },
          // checked toggle
          colorSecondary: {
            "&$checked": {
              color: host?.overridesMuiSwitchCheckedColor ?? "#2B8E94",
              "&:hover": {
                backgroundColor: alpha(
                  host?.overridesMuiSwitchCheckedColor ?? "#2B8E94",
                  0.1,
                ),
              },
            },
            "&$checked + $track": {
              backgroundColor:
                host?.overridesMuiSwitchCheckedColor ?? "#2B8E94",
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          fontSizeSmall: {
            fontSize: 12,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            ".grecaptcha-badge": {
              visibility: "hidden",
            },
            html: {
              WebkitFontSmoothing: "auto",
            },
            h1: headingTypography,
            h2: headingTypography,
            h3: headingTypography,
            h4: headingTypography,
            h5: headingTypography,
            h6: headingTypography,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            "&::-webkit-scrollbar": { display: "none" },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            "&::-webkit-scrollbar": { display: "none" },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            margin: 0,
            marginTop: 3,
          },
        },
      },
    },
  });
};

export default createTheme;
