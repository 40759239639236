import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import {
  OptimizelyProvider,
  ReactSDKClient,
  createInstance,
  enums,
  setLogLevel,
} from "@optimizely/react-sdk";
import { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { appWithTranslation, useTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import React, { ReactElement, useEffect } from "react";
import TagManager from "react-gtm-module";

import optimizelyDatafile from "../lib/optimizely/datafile.json";
import ContentProvider, { Content } from "../src/components/ContentProvider";
import createTheme from "../src/createTheme";
/**
 * This is an auto fetched datafile during build time using the SDK key provided as an env variable. The datafile
 * is retrieved at build time to enable it to be used at the Edge in Vercel.
 * See https://github.com/optimizely/vercel-examples/tree/main/edge-functions/feature-flag-optimizely for details
 * on how the Deploy Hooks need to be configure to trigger a fresh build upon changing flags.
 */
import createApolloClient from "../src/util/createApolloClient";
import useOptimizelyCookies from "../src/util/useOptimizelyCookies";

import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";

// Instantiate an Optimizely client
const optimizely: ReactSDKClient = createInstance({
  // Use prefetched datafile on first use.
  datafile: optimizelyDatafile,
  // Providing SDK key will enable SDK to keep the datafile auto updated for subsequent uses.
  sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
  // Recommended in repo below for NextJS, to always provide values for datafileOptions.
  // The same piece of code works for server and client and default values for these options are different for both.
  // Reference: https://github.com/optimizely/next-js-example-react-sdk/tree/sherry/initial-draft
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 60 * 1000 * 10, // 10 minute in ms
  },
});

// Set Optimizely logging to only error in production.
setLogLevel(
  process.env.NODE_ENV === "production" ?
    enums.LOG_LEVEL.ERROR
  : enums.LOG_LEVEL.WARNING,
);

type AppProps<P = unknown> = {
  pageProps: P;
} & Omit<NextAppProps<P>, "pageProps">;

const App = (
  props: AppProps<{ content?: Content; key?: string | null }>,
): ReactElement => {
  const { Component, pageProps } = props;
  const client = createApolloClient();
  const { t, i18n } = useTranslation();
  const { visitorId, isTestAudience } = useOptimizelyCookies();
  useEffect(() => {
    // Initialize Google Tag Manager unless otherwise specified.
    if (
      process.env.GTM_ID != null &&
      process.env.GTM_ID !== "" &&
      (pageProps.content?.host?.allowAnalytics ?? true)
    ) {
      // Initialize Tag Manager
      TagManager.initialize({ gtmId: process.env.GTM_ID });
    }
  }, []);

  return (
    <AppCacheProvider {...props}>
      <ApolloProvider client={client}>
        <DefaultSeo
          defaultTitle={
            pageProps.content?.host ?
              t("{{name}} - Find A Helpline", {
                name: pageProps.content.host.name,
              })
            : t(
                "Suicide Hotlines & Crisis Helplines | Free, 24/7 Chat, Text & Phone",
              )
          }
          titleTemplate={
            pageProps.content?.host ?
              t("%s | {{name}}", { name: pageProps.content.host.name })
            : "%s"
          }
          description={t(
            "Talk with a crisis counselor today. Verified global support for anxiety, depression, suicide prevention, domestic violence, sexual abuse and more.",
          )}
          openGraph={{
            title: t(
              "Find A Helpline | Free emotional support in 130+ countries",
            ),
            description: t(
              "Global vetted directory of helplines, hotlines and crisis lines. Chat, text or phone support with suicide, anxiety, depression, domestic violence, gender& sexual identity and more.",
            ),
            type: "website",
            locale: "en",
            images: [{ url: "https://findahelpline.com/og.png" }],
          }}
          twitter={{
            handle: "@findahelpline",
            site: "@findahelpline",
            cardType: "summary_large_image",
          }}
        />
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          {pageProps.content?.host?.favicon?.url ?
            <link rel="icon" href={pageProps.content.host.favicon.url} />
          : <link rel="icon" href="/favicon.svg" />}
        </Head>
        <ContentProvider {...pageProps.content}>
          <ThemeProvider theme={createTheme(pageProps.content?.host)}>
            <OptimizelyProvider
              optimizely={optimizely}
              user={{
                id: visitorId,
                attributes: {
                  host: pageProps.content?.host?.name ?? null, // Could use id, but more readable for Elliot in Optimizely.
                  page: pageProps.key ?? null, // Provide the key so we can target certain result pages.
                  language: i18n.language, // Provide the language code for targeting.
                  testAudience: isTestAudience,
                },
              }}
              isServerSide={false}
            >
              <CssBaseline />
              <Component {...pageProps} />
            </OptimizelyProvider>
          </ThemeProvider>
        </ContentProvider>
      </ApolloProvider>
    </AppCacheProvider>
  );
};

export default appWithTranslation(App);
