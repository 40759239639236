import { getCookie } from "cookies-next";

import { FINDAHELPLINE_VISITOR_ID_COOKIE_NAME } from "../../middleware";

// Name of cookie should we want to force the user into the test audience bucket.
// See https://docs.developers.optimizely.com/experimentation/v4.0.0-full-stack/docs/use-a-qa-audience
const TEST_AUDIENCE_COOKIE_NAME = "optimizely_test_cookie";

type OptimizelyCookies = {
  visitorId: string;
  isTestAudience: boolean;
};

const useOptimizelyCookies = (): OptimizelyCookies => {
  const isTestAudience = getCookie(TEST_AUDIENCE_COOKIE_NAME) === "true";
  const visitorId =
    (getCookie(FINDAHELPLINE_VISITOR_ID_COOKIE_NAME) as string) ?? "anonymous";

  return { visitorId, isTestAudience };
};

export default useOptimizelyCookies;
