// eslint-disable-next-line @next/next/no-server-import-in-page
import { NextRequest, NextResponse } from "next/server";
import { v4 as uuidv4 } from "uuid";

export const FINDAHELPLINE_VISITOR_ID_COOKIE_NAME = "findahelplineID";

export const config = {
  matcher: [
    /*
     * Match all paths except for:
     * 1. /api routes
     * 2. /_next (Next.js internals)
     * 3. /_static (Next static content)
     * 4. /partners and /illustrations (images directories within /public)
     * 5. all root files inside /public (e.g. /favicon.ico)
     */
    "/((?!api/|_next/|_static/|partners/|illustrations/|avatar/|[\\w-]+\\.\\w+).*)",
    // Ensure the root path is matched.
    "/",
  ],
};

export async function middleware(req: NextRequest): Promise<NextResponse> {
  const nextUrl = req.nextUrl.clone();

  // Get hostname of request (e.g. demo.vercel.pub, demo.localhost:3000)
  let hostname = req.headers
    .get("host")
    .replace(".localhost:3000", `.${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`);

  // special case for Vercel preview deployment URLs
  if (
    hostname.includes("---") &&
    hostname.endsWith(`.${process.env.NEXT_PUBLIC_VERCEL_DEPLOYMENT_SUFFIX}`)
  ) {
    hostname = `${hostname.split("---")[0]}.${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`;
  }

  const searchParams = nextUrl.searchParams.toString();
  // Get the pathname of the request (e.g. /, /about, /blog/first-post)
  const path = `${nextUrl.pathname}${searchParams.length > 0 ? `?${searchParams}` : ""}`;

  const url = new URL(`/${nextUrl.locale}/${hostname}${path}`, req.url);

  const response = NextResponse.rewrite(url);

  // Saving a randomly generated id in the cookie so it can be used to distinguish individual users
  // for feature navigational decisions or features. E.g. utilised by optimizely and our feature flags for segmentation.
  if (!req.cookies.has(FINDAHELPLINE_VISITOR_ID_COOKIE_NAME)) {
    const visitorId = uuidv4();
    response.cookies.set(FINDAHELPLINE_VISITOR_ID_COOKIE_NAME, visitorId, {
      path: "/",
      secure: process.env.NODE_ENV === "production",
      sameSite: "strict",
      maxAge: 60 * 60 * 24 * 30, // Retain this cookie for 30 days so the user gets a consistent experience if they return.
    });
  }

  return response;
}
