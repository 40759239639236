import localFont from "next/font/local";

// Defining our local fonts - this needs to be done in the module scope
const builderSans = localFont({
  src: [
    { path: "./BuilderSans-Regular.woff2", style: "normal", weight: "400" },
    { path: "./BuilderSans-SemiBold.woff2", style: "normal", weight: "600" },
    { path: "./BuilderSans-Bold.woff2", style: "normal", weight: "700" },
  ],
});

// Hash mapping font identifiers to their respective font styles.
const fonts = {
  builderSans: builderSans,
};

/**
 * Applies font styles to placeholders within a string.
 *
 * This function searches for placeholders in the format of {{fontIdentifier}}
 * within the input string and replaces them with the corresponding font family
 * from the `fonts` hash. The font identifiers are case-insensitive and spaces
 * are ignored.
 *
 * @param {string} str - The input string containing font placeholders.
 * @returns {string} - The modified string with font family styles applied.
 */
function applyFonts(str: string): string {
  Object.entries(fonts).forEach(([key, value]) => {
    // Converts our key to a placeholder e.g. builderSans -> {{builder sans}}
    const placeholder = new RegExp(
      `{{${key
        .replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase()}}}`,
      "g",
    );
    str = str.replace(placeholder, value.style.fontFamily);
  });
  return str;
}

export { fonts, applyFonts };
